<template>
  <div class="commodityManage">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t.noMoreData"
      @load="onLoad"
    >
      <van-sticky>
        <van-row type="flex" align="center" class="tabList commodity-top-row">
          <van-col class="tabItem" @click="changeType(1)">
            <section :class="['itemTop', type === 1 ? 'itemTopAction' : '']">
              <p class="itemTopName">{{ $t.putaway }}</p>
              <!-- <p class="itemTopNum">(1)</p> -->
            </section>
            <div
              :class="['itemBorder', type === 1 ? 'itemBorderAction' : '']"
            ></div>
          </van-col>
          <van-col class="tabItem" @click="changeType(0)">
            <section :class="['itemTop', type === 0 ? 'itemTopAction' : '']">
              <p class="itemTopName">{{ $t.soldOut }}</p>
              <!-- <p class="itemTopNum">(1)</p> -->
            </section>
            <div
              :class="['itemBorder', type === 0 ? 'itemBorderAction' : '']"
            ></div>
          </van-col>
          <van-col class="tabItem" @click="changeType(2)">
            <p :class="['tabItemTotal', type === 2 ? 'itemTopAction' : '']">
              {{ $t.all }}
            </p>
            <div
              :class="['itemBorder', type === 2 ? 'itemBorderAction' : '']"
            ></div>
          </van-col>
        </van-row>
      </van-sticky>
      <div class="list">
        <sore-list
          :listProduct="listProduct"
          @editB_ProductStatus="editB_ProductStatus"
          @editB_ProductTop="editB_ProductTop"
          @deleteB_Product="deleteB_Product"
        ></sore-list>
      </div>
    </van-list>
  </div>
</template>
<script>
import Vue from "vue";
import { Sticky, Row, Col, List } from "vant";
Vue.use(Sticky).use(Row).use(List).use(Col);
Vue.use(List).use(Sticky);
import SoreList from "./components/SoreList";

export default {
  name: "CommodityManage",
  components: {
    SoreList,
  },
  data() {
    return {
      className: "commodity-top-row",
      listProduct: [],
      type: 1,
      loading: true,
      //params
      pageIndex: 1,
      finished: false,
    };
  },
  created() {
    const { Offline } = this.$route.query;
    if (Offline) {
      this.changeType(0);
    }
  },
  mounted() {
    window.document.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        document.getElementsByClassName(this.className)[0].style.paddingTop =
          "calc(15px + env(safe-area-inset-top))";
      } else {
        document.getElementsByClassName(this.className)[0].style.paddingTop =
          "15px";
      }
    });
    //首次加载数据
    this.getShopProductsByPage();
  },
  methods: {
    resetParams() {
      this.pageIndex = 1;
      this.finished = false;
    },
    changeType(type) {
      this.loading = true;
      this.type = type;
      this.resetParams();
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      if (this.type !== 0 && this.type !== 1) {
        param = {
          PageIndex: this.pageIndex,
        };
      } else {
        param = {
          PageIndex: this.pageIndex,
          ScreenValues: this.type,
        };
      }
      this.$api.product
        .loadShopProductsByPage(param)
        .then((res) => {
          const data = res.data;
          this.listProduct = data;
          this.loading = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 首次加载数据
    getShopProductsByPage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      if (this.type !== 0 && this.type !== 1) {
        param = {
          PageIndex: 1,
        };
      } else {
        param = {
          PageIndex: 1,
          ScreenValues: this.type,
        };
      }
      this.$api.product
        .loadShopProductsByPage(param)
        .then((res) => {
          const data = res.data;
          this.listProduct = data;
          this.loading = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    onLoad() {
      let param = {};
      if (this.type !== 0 && this.type !== 1) {
        param = {
          PageIndex: this.pageIndex + 1,
        };
      } else {
        param = {
          PageIndex: this.pageIndex + 1,
          ScreenValues: this.type,
        };
      }
      this.$api.product
        .loadShopProductsByPage(param)
        .then((res) => {
          const data = res.data;
          if (data === "" || data === null || data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.listProduct = this.listProduct.concat(data);
            this.pageIndex = this.pageIndex + 1;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    //上下架
    editB_ProductStatus(ProductNumber, Status) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        ProductNumber: ProductNumber,
        Status: Status,
      };
      this.$api.product
        .editB_ProductStatus(param)
        .then((res) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: res.message,
          });
          this.getShopProductsByPage();
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
    },
    //上下架
    editB_ProductTop(ProductNumber, IsTop) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        ProductNumber: ProductNumber,
        IsTop: IsTop,
      };
      this.$api.product
        .editB_ProductTop(param)
        .then((res) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: res.message,
          });
          this.getShopProductsByPage();
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
    },
    deleteB_Product(ProductNumber) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        ProductNumber: ProductNumber,
      };
      this.$api.product
        .deleteProductByProductNumber(param)
        .then((res) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: res.message,
          });
          this.getShopProductsByPage();
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.commodityManage {
  width: 100%;
  font-size: 10px;
  .list {
    width: 100%;
  }
  .commodity-top-row {
    transition: padding-top 0.1s;
  }
  .tabList {
    width: 100%;
    padding-top: 15px;
    text-align: center;
    background-color: #ffffff;
    .tabItem {
      width: 33.33%;
      text-align: center;
      font-size: 14px;
      color: #333333;
      .tabItemTotal {
        margin: 0;
        padding: 0;
      }
      .itemTop {
        @include puiblicFlex;
        text-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        .itemTopName {
          margin: 0;
          padding: 0;
        }
        .itemTopNum {
          margin: 0;
          padding: 0;
        }
      }
      .itemTopAction {
        color: #43cd9a;
      }
      .itemBorder {
        height: 4px;
        width: 30px;
        // background-color: #43cd9a;
        margin: 0 auto;
        border-radius: 10px;
        margin-top: 10px;
        opacity: 0;
      }
      .itemBorderAction {
        background-color: #43cd9a;
        opacity: 1;
      }
    }
  }
}
</style>
